<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>Use the figure shown here to answer each of the questions below.</p>

      <img :src="hybridizationImage" width="350px" class="mb-5 ml-10" />
      <p class="mb-3">a) Determine the hybridization of the atom shown in red for each structure</p>

      <v-simple-table class="mb-7">
        <thead>
          <tr>
            <th style="font-size: 15px">Structure</th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{sp}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{sp}^2$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{sp}^3$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{sp}^3\text{d}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{sp}^3\text{d}^2$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{sp}^3\text{d}^3$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(structure, i) in structures" :key="structure">
            <td>
              <stemble-latex :content="`$\\text{${structure}}$`" />
            </td>
            <td
              v-for="hybridization in hybridizations"
              :key="`${structure}${hybridization}`"
              style="text-align: center"
            >
              <v-radio-group v-model="inputs[`input${i + 1}`]" :disabled="!allowEditing">
                <v-radio :value="hybridization" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-3">
        b) Determine the molecular geometry around the atom shown in red for each of the 4
        structures.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Structure</th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{Trigonal Planar}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{Square Planar}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{Tetrahedral}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{Pyramidal}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{Linear}$" />
            </th>
            <th style="font-size: 13px">
              <stemble-latex content="$\text{Bent}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(structure, i) in structures" :key="structure">
            <td>
              <stemble-latex :content="`$\\text{${structure}}$`" />
            </td>
            <td
              v-for="geometry in molecularGeometries"
              :key="`${structure}${geometry}`"
              style="text-align: center"
            >
              <v-radio-group v-model="inputs[`input${i + 5}`]" :disabled="!allowEditing">
                <v-radio :value="geometry" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question255',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
      structures: ['A', 'B', 'C', 'D'],
      hybridizations: ['sp', 'sp2', 'sp3', 'sp3d', 'sp3d2', 'sp3d3'],
      molecularGeometries: [
        'trigonalPlanar',
        'squarePlanar',
        'tetrahedral',
        'pyramidal',
        'linear',
        'bent',
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content.value;
    },
    hybridizationImage() {
      return `/img/assignments/hybridizationFigure${this.versionVariable}.png`;
    },
  },
};
</script>
